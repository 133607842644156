<template>
  <sdModal
    centered
    type="primary"
    :title="i18n.t('profileView.createNewWallet')"
    class="ant-modal-action create-wallet"
    :visible="visible"
    :onCancel="changeWalletForm"
    :width="340"
  >
    <div class="action__wrapper">
      <div class="action__inner">
        <a-form :model="createWalletData" :rules="rules" @finish="createWallet">
          <a-form-item :label="i18n.t('editWalletView.currencyExchange')" class="input__wrapper">
            <a-select
              v-model:value="createWalletData.system"
              :placeholder="i18n.t('editWalletView.currencyExchange')"
              size="large"
              ref="select"
              class="action__select"
            >
              <a-select-option v-for="currency of walletCurrencies" :value="currency.value" :key="currency.value">
                <p>
                  <i>
                    <img
                      :src="require('@/static/img/icon/' + currency.label + '.svg')"
                      class="crypto-icon"
                      :alt="currency.label"
                    />
                  </i>
                  &nbsp;
                  {{ i18n.t('transactions.' + currency.label) }}
                </p>
              </a-select-option>
            </a-select>
          </a-form-item>
          <a-form-Item
            ref="wallet"
            name="wallet"
            :label="i18n.t('editWalletView.walletNumber')"
            :class="errorsValues.wallet ? 'has-error' : ''"
            class="input__wrapper"
          >
            <a-input v-model:value="createWalletData.wallet" size="large" />
            <br />
            <small v-if="errorsValues.wallet">
              {{ i18n.t('editWalletView.walletNumber') }} {{ errorsValues.wallet }}
            </small>
          </a-form-Item>

          <a-form-Item
            ref="name"
            name="name"
            :label="i18n.t('editWalletView.name')"
            :class="errorsValues.wallet ? 'has-error' : ''"
            class="input__wrapper"
          >
            <a-input v-model:value="createWalletData.name" size="large" />
            <br />
            <small v-if="errorsValues.name"> {{ i18n.t('editWalletView.walletName') }} {{ errorsValues.name }} </small>
          </a-form-Item>
          <div class="setting-form-actions">
            <sdButton size="large" htmlType="submit" type="success" :disabled="isLoading">
              {{ i18n.t('profileView.save') }}
            </sdButton>
          </div>
        </a-form>
      </div>
    </div>
  </sdModal>
</template>

<script>
import { computed, reactive, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';

export default {
  emits: ['update:visible'],
  props: {
    isLoading: {
      type: Boolean,
      default: false,
    },

    walletCurrencies: {
      type: Object,
      default() {
        return {};
      },
    },
    visible: {
      type: Boolean,
      default: false,
    },

    profileEmail: {
      type: [String, null],
      default: null,
    },
  },

  setup(props, { emit }) {
    const { state, dispatch } = useStore();
    const i18n = reactive(useI18n());
    const errorsValues = ref({ name: null, wallet: null });
    const createWalletData = ref({ system: 4, email: props.profileEmail });

    const error = computed(() => state.profile.error);

    const nameValidator = (rule) => {
      if (errorsValues.value.name && rule.field == 'name') {
        errorsValues.value.name = null;
      } else if (errorsValues.value.wallet && rule.field == 'wallet') {
        errorsValues.value.wallet = null;
      }
      return Promise.resolve();
    };

    const rules = reactive({
      system: [
        {
          required: true,
          message: i18n.t('authPage.errorSystem'),
          trigger: 'blur',
        },
      ],
      wallet: [
        {
          required: true,
          message: i18n.t('authPage.errorWallet'),
          trigger: 'blur',
        },
        {
          validator: nameValidator,
          trigger: 'change',
        },
      ],

      name: [
        {
          required: true,
          message: i18n.t('authPage.errorFirstName'),
          trigger: 'blur',
        },
        {
          validator: nameValidator,
          trigger: 'change',
        },
      ],
    });

    const changeWalletForm = () => {
      emit('update:visible', false);
    };

    const createWallet = () => {
      dispatch('createNewWallet', createWalletData.value).then(() => {
        if (!error.value) {
          changeWalletForm();
        } else {
          Object.entries(error.value.data).map((value) => {
            errorsValues.value[value[0]] = value[1][0];
          });
        }
      });
    };

    return {
      i18n,
      errorsValues,
      rules,
      createWalletData,
      error,
      nameValidator,
      changeWalletForm,
      createWallet,
    };
  },
};
</script>

<style scoped lang="scss">
.setting-form-actions {
  margin-top: 20px;
  button {
    width: 100%;
  }
}
</style>
